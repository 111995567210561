<template>
  <section>
    <v-sheet v-if="!loading">
      <v-list>
        <section v-for="item in courses" :key="item.id">
          <v-list-item class="" exact :to="{name: 'Mobile User Course Details', params: {uuid: item.uuid}}">
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center roboto f14 fw500 secondary-3--text">
                <v-avatar class="mr-3">
                  <v-img contain :src="item.image?item.image.url:''"/>
                </v-avatar>
                {{item.title}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon size="20">mdi-dots-vertical</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider/>
        </section>
      
      </v-list>
    </v-sheet>
    <circular v-else/>
  </section>
</template>

<script>
import {
  ref,
  reactive,
  onMounted,
  computed
} 
from '@vue/composition-api';
import store from '@/store'
import Avatar from '../../../components/main/Avatar.vue';

export default {
  components: { Avatar },
  setup() {
    const loading = ref(true)
    const courses = computed(() => store.state.usr.courses)

    onMounted(async () => {
      await store.dispatch('usr/getCoursesAction')
      .then(_ => {
        loading.value = false
      })
    })

    return {
      loading,
      courses,
    }
  }
}
</script>

<style>

</style>